import React, { useContext, useEffect } from "react";
import Navigation from "./Components/Navigation";
import { Route, Routes, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home";
import Library from "./pages/Library";
import LibraryEditor from "./pages/LibraryEditor";
import LibraryGenerator from "./pages/LibraryGenerator";
import LibraryImportSong from "./pages/LibraryImportSong";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Vocabulary from "./pages/Vocabulary";
import VocabularyImporter from "./pages/VocabularyImporter";
import Statistics from "./pages/Statistics";
import Reader from "./pages/Reader";
import Reader2 from "./pages/Reader2";
import Reader3 from "./pages/Reader3";
import Music from "./pages/Music";
import ReaderHeight from "./pages/ReaderHeight";
import Player from "./pages/Player";
import Review from "./pages/Review";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import Admin from "./pages/Admin";
import ReleaseNotes from "./pages/ReleaseNotes";
import TermsOfService from "./pages/TermsOfService";
import ComprehensibleInput from "./pages/ComprehensibleInput";
import NotFound from "./pages/NotFound";
import CookieDetection from "./pages/CheckIfCookiesAreEnabled";
import Footer from "./Components/Footer";

import Spanish from "./pages/comprehensible-input/Spanish";
import Estonian from "./pages/comprehensible-input/Estonian";
import French from "./pages/comprehensible-input/French";
import Finnish from "./pages/comprehensible-input/Finnish";
import German from "./pages/comprehensible-input/German";
import Italian from "./pages/comprehensible-input/Italian";
import Norwegian from "./pages/comprehensible-input/Norwegian";
import Danish from "./pages/comprehensible-input/Danish";
import Polish from "./pages/comprehensible-input/Polish";
import Portuguese from "./pages/comprehensible-input/Portuguese";
import Russian from "./pages/comprehensible-input/Russian";
import Latvian from "./pages/comprehensible-input/Latvian";
import Swedish from "./pages/comprehensible-input/Swedish";
import Thai from "./pages/comprehensible-input/Thai";
import Vietnamese from "./pages/comprehensible-input/Vietnamese";

import LanguageCourse from "./pages/LanguageCourse";

import ScrollToTop from "./utils/ScrollToTop";

// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from "./context/AuthContext";

import { FullStory } from "@fullstory/browser";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import ImportDuolingoVocabulary from "./pages/ImportDuolingoVocabulary";

function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-XFLY2RT4FR");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  // Effect for identifying the user with FullStory
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      context.email &&
      context.user_id
    ) {
      FullStory.identify(context.user_id, {
        email: context.email,
        plan: context.plan,
      });
      console.log("FullStory user identified.");
    }
  }, [context.email, context.user_id]);

  useEffect(() => {
    context.getAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (context.has_been_verified === undefined) {
    return <div>Loading...</div>;
  }

  const isReaderRoute = location.pathname.includes("/reader");
  const isReviewRoute = location.pathname.includes("/review");
  const isPlayerRoute = location.pathname.includes("/player");
  const isMusicRoute = location.pathname.includes("/music");

  return (
    <>
      {!isReaderRoute && !isReviewRoute && !isMusicRoute && !isPlayerRoute && (
        <Navigation />
      )}
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={context.user_id ? <Navigate to="/library" /> : <Home />}
        />
        <Route path="/home" element={<Home />} />
        {context.user_id && <Route path="/library" element={<Library />} />}
        {context.user_id && (
          <Route path="/library/editor/:id" element={<LibraryEditor />} />
        )}
        {context.user_id && (
          <Route path="/library/editor" element={<LibraryEditor />} />
        )}
        {context.user_id && (
          <Route path="/library/generator" element={<LibraryGenerator />} />
        )}
        {context.user_id && (
          <Route path="/library/import-song" element={<LibraryImportSong />} />
        )}

        {context.user_id && (
          <Route path="/vocabulary/import" element={<VocabularyImporter />} />
        )}
        {context.user_id && (
          <Route path="/vocabulary" element={<Vocabulary />} />
        )}
        {context.user_id && (
          <Route path="/statistics" element={<Statistics />} />
        )}
        <Route path="/reader/:id" element={<Reader />} />
        {context.user_id && context.email.includes("atjackiejohns") && (
          <Route path="/admin" element={<Admin />} />
        )}
        <Route path="/reader2/:id" element={<Reader2 />} />
        <Route path="/reader3/:id" element={<Reader3 />} />
        <Route path="/music/:id" element={<Music />} />
        {context.user_id && context.email.includes("atjackiejohns") && (
          <Route path="/reader-height" element={<ReaderHeight />} />
        )}
        <Route path="/player/:id" element={<Player />} />
        {context.user_id && (
          <Route path="/vocabulary/review" element={<Review />} />
        )}
        {context.user_id && (
          <Route path="/vocabulary/review/:articleId" element={<Review />} />
        )}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/change-password" element={<ChangePassword />} />
        {context.user_id && <Route path="/account" element={<Account />} />}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-detector" element={<CookieDetection />} />

        <Route path="/comprehensible-input" element={<ComprehensibleInput />} />
        <Route path="/spanish-comprehensible-input" element={<Spanish />} />
        <Route path="/estonian-comprehensible-input" element={<Estonian />} />
        <Route path="/finnish-comprehensible-input" element={<Finnish />} />
        <Route path="/french-comprehensible-input" element={<French />} />
        <Route path="/german-comprehensible-input" element={<German />} />
        <Route path="/italian-comprehensible-input" element={<Italian />} />
        <Route path="/norwegian-comprehensible-input" element={<Norwegian />} />
        <Route path="/danish-comprehensible-input" element={<Danish />} />
        <Route path="/polish-comprehensible-input" element={<Polish />} />
        <Route
          path="/portuguese-comprehensible-input"
          element={<Portuguese />}
        />
        <Route path="/russian-comprehensible-input" element={<Russian />} />
        <Route path="/latvian-comprehensible-input" element={<Latvian />} />
        <Route path="/swedish-comprehensible-input" element={<Swedish />} />
        <Route path="/thai-comprehensible-input" element={<Thai />} />
        <Route path="/vietnamese-comprehensible-input" element={<Vietnamese />} />
        <Route
          path="/course/:language-learn-online"
          element={<LanguageCourse />}
        />
        <Route
          path="/import-duolingo-vocabulary"
          element={<ImportDuolingoVocabulary />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {!isReaderRoute && !isReviewRoute && !isPlayerRoute && !isMusicRoute && (
        <Footer />
      )}
    </>
  );
}

export default App;
